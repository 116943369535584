<template>
  <div>
    <Table
      stripe
      size="small"
      :data="tableData"
      :columns="dataColumns"
    ></Table>
  </div>
</template>

<script>
import { formartMspTaskitemStatus, formartMspTaskitemRephotoStatus } from '@/utils/tagStatus'
import { getDeviceUndoneTroubleTaskitemList } from '@/api/msp/device'
export default {
  props: {
    deviceId: [Number]
  },
  data () {
    return {
      tableData: [],
      dataColumns: [
        { title: '作业日期', align: 'center', key: 'actionDate' },
        {
          title: '维修项',
          align: 'center',
          width: 200,
          render: (h, params) => {
            return h('Tooltip', {
              props: {
                content: params.row.taskitemRepair.repairName || '',
                transfer: true,
                'max-width': '200px'
              }
            }, params.row.taskitemRepair.repairName && params.row.taskitemRepair.repairName.length > 12 ? params.row.taskitemRepair.repairName.substr(0, 11) + '...' : params.row.taskitemRepair.repairName)
          }

        },
        {
          title: '状态',
          key: 'statusName',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              formartMspTaskitemStatus(h, params.row.status, params.row.statusName),
              formartMspTaskitemRephotoStatus(h, params.row.rephoto)
            ])
          }
        },
        { title: '备注', align: 'center', key: 'remark', ellipsis: true, tooltip: true }
      ]
    }
  },
  methods: {
    initData () {
      getDeviceUndoneTroubleTaskitemList({ deviceId: this.deviceId }).then(res => {
        if (res && !res.errcode) {
          this.tableData = res
          this.$store.commit('set_undone_trouble_amount', this.tableData.length)
        } else {
          this.tableData = []
          this.$store.commit('set_undone_trouble_amount', 0)
        }
      })
    }
  },
  watch: {
    deviceId: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          this.initData()
        }
      }
    }
  }
}
</script>
