<template>
  <div>
    <Tabs value="1">
      <TabPane
        label="设置"
        name="1"
      >
        <div
          v-if="dataSource===2"
          class="m-b-10"
        ><span>问题描述: </span>{{curDeviceRapairInfo.category1Name}} - {{curDeviceRapairInfo.category2Name}}</div>

        <Form
          ref="newTaskData"
          :model="newTaskData"
          :rules="ruleValidate"
          label-position="top"
        >
          <FormItem
            label="作业日期"
            prop="actionDate"
          >
            <DatePicker
              placeholder="选择作业日期"
              size="small"
              v-model="newTaskData.actionDate"
              type="date"
              style="width:100%"
              :editable="false"
              :clearable="false"
              @on-change="changeActionDate"
            ></DatePicker>
          </FormItem>
          <FormItem
            label="维修项"
            prop="repairId"
          >
            <Select
              v-model="newTaskData.repairId"
              size="small"
              placeholder="选择维修项"
              @on-change="changeRepairId"
            >
              <Option
                v-for="item in repairDictList"
                :key="'repairId'+item.id"
                :value="item.id"
              >{{item.name}}</Option>
            </Select>
            <div class="m-t-5 text-orange">{{checkTips}}</div>
          </FormItem>
          <FormItem
            label="质保情况"
            v-if="repairInfo"
          >
            <div v-if="repairInfo.lastDate"><label class="title">上次维修时间</label>{{repairInfo.lastDate}}</div>
            <Row :gutter="8">
              <i-col span="6"><label class="title">是否在质保期</label>{{repairInfo.inPeriod?"是":"否"}}</i-col>
              <i-col span="6"><label class="title">质保天数</label>{{repairInfo.period}} 天</i-col>
              <!-- <i-col span="6"><label class="title">材料费用</label>{{repairInfo.materialCost}} 元</i-col>
              <i-col span="6"><label class="title">人工费用</label>{{repairInfo.workCost}} 元</i-col> -->
            </Row>
          </FormItem>
          <FormItem
            label="计费项"
            v-if="repairInfo"
          >
            <Row :gutter="8">
              <i-col span="12">
                <Checkbox
                  size="small"
                  v-model="materialCost"
                  @on-change="choiceMaterialCost"
                >材料费用</Checkbox>
              </i-col>
              <i-col span="12">
                <Checkbox
                  size="small"
                  v-model="workCost"
                  @on-change="choiceWorkCost"
                >人工费用</Checkbox>
              </i-col>
              <!-- <i-col span="8"></i-col> -->
            </Row>
            <Row :gutter="8">
              <i-col span="12">
                <Row
                  :gutter="8"
                  v-if="materialCost"
                >
                  <i-col span="12">
                    <Select
                      v-model="newTaskData.materialServiceId"
                      :clearable="true"
                      placeholder="选择材料费用"
                      size="small"
                      @on-change="handleChangeMaterial"
                    >
                      <Option
                        v-for="item in repairInfo.materialCostList"
                        :key="'work_'+item.id"
                        :value="item.id"
                      >{{item.price+' 元/'+item.unitName}}</Option>
                    </Select>
                  </i-col>
                  <i-col span="12">
                    <InputNumber
                      size="small"
                      :min="0"
                      :formatter="value => `${value}`"
                      :parser="value => value.replace('.', '')"
                      v-model="newTaskData.materialCount"
                    ></InputNumber><span class="p-l-10">{{materialTotalCost}}元</span>
                  </i-col>
                </Row>
              </i-col>
              <i-col span="12">
                <Row
                  :gutter="8"
                  v-if="workCost"
                >
                  <i-col span="12">
                    <Select
                      v-model="newTaskData.workServiceId"
                      :clearable="true"
                      placeholder="选择人工费用"
                      size="small"
                      @on-change="handleChangeWorkcost"
                    >
                      <Option
                        v-for="item in repairInfo.workCostList"
                        :key="'work_'+item.id"
                        :value="item.id"
                      >{{item.price+' 元/'+item.unitName+' ('+item.startTime+' - '+item.endTime+')'}}</Option>
                    </Select>
                  </i-col>
                  <i-col span="12">
                    <InputNumber
                      size="small"
                      :min="0"
                      :formatter="value => `${value}`"
                      :parser="value => value.replace('.', '')"
                      v-model="newTaskData.workCount"
                    ></InputNumber><span class="p-l-10">{{workTotalCost}}元</span>
                  </i-col>
                </Row>
              </i-col>
            </Row>
            <div class="text-right">
              <div v-if="materialCost||workCost">
                <span>总计：{{totalCost}}元</span>
              </div>
            </div>
          </FormItem>
          <FormItem label="文件选择">
            <div
              class="m-b-5"
              v-if="dataSource===2"
            >
              <Checkbox
                size="small"
                v-model="newTaskData.useReportPic"
              >使用故障上报图片</Checkbox>
            </div>
            <Upload
              multiple
              :before-upload="handleUpload"
              accept="video/*,image/*"
              :data="newTaskData"
              type="drag"
              action
            >
              <div style="padding: 5px 0">
                <Icon
                  type="ios-cloud-upload"
                  size="40"
                  style="color: #3399ff"
                ></Icon>
                <p>选择上传文件</p>
              </div>
            </Upload>
            <p class="text-orange">提示：</p>
            <p>上传文件类型只支持图片和视频。</p>
            <Row v-show="fileList.length||selectedTaskitemFiles.length">
              <i-col span="18">
                <b>文件名称</b>
              </i-col>
              <!-- <i-col span="5">
            <b>文件大小</b>
          </i-col> -->
              <i-col
                span="6"
                class="text-center"
              >
                <b>操作</b>
              </i-col>
            </Row>
            <hr v-show="fileList.length" />
            <Row
              v-for="(file,index) in selectedTaskitemFiles"
              :key="'exitsFile_'+index"
              v-show="selectedTaskitemFiles.length"
            >
              <i-col span="18">{{file.fileName.length>20?file.fileName.substring(0,19)+'...':file.fileName}}</i-col>
              <!-- <i-col span="5">{{(file.size / 1024).toFixed(2)}} KB</i-col> -->
              <i-col
                span="6"
                class="text-center"
              >
                <a
                  class="delete"
                  @click="handleRemoveExitsFiles(file)"
                >删除</a>
              </i-col>
              <i-col span="24">
                <Divider
                  dashed
                  style="margin:5px 0"
                />
              </i-col>
            </Row>
            <Row
              v-for="(file,fileIndex) in fileList"
              :key="fileIndex"
              v-show="fileList.length"
            >
              <i-col span="18">{{file.name.length>20?file.name.substring(0,19)+'...':file.name}}</i-col>
              <!-- <i-col span="5">{{(file.size / 1024).toFixed(2)}} KB</i-col> -->
              <i-col
                span="6"
                class="text-center"
              >
                <a
                  class="delete"
                  @click="handleRemove(file)"
                >删除</a>
              </i-col>
              <i-col span="24">
                <Divider
                  dashed
                  style="margin:5px 0"
                />
              </i-col>
            </Row>
          </FormItem>
          <FormItem label="备注">
            <Input
              type="textarea"
              size="small"
              :rows="3"
              v-model.trim="newTaskData.remarks"
              placeholder="备注"
            />
          </FormItem>
          <FormItem>
            <div class="text-right">
              <Button
                type="primary"
                :loading="submitLoading"
                @click="handleSubmit"
              >确认设置</Button>
            </div>

          </FormItem>
        </Form>
      </TabPane>
      <TabPane
        :label="label2"
        name="2"
      >
        <div class="p-t-10 workplatform-title">当前设备未完成的故障任务</div>
        <undone-trouble :deviceId="deviceId"></undone-trouble>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { getRepairDictList } from '@/api/msp/dictionary'
import { getRepairInfo } from '@/api/msp/taskitem'
import { check } from '@/api/msp/demandV3'

import UndoneTrouble from './UndoneTrouble.vue'

export default {
  props: {
    opreateType: {
      type: Number,
      default: 1
    },
    taskId: {
      type: Number,
      required: true
    },
    dataSource: {
      type: Number,
      default: 1
    },
    deviceId: {
      require: true,
      type: Number
    },
    selectedResourceIdArray: [Array],
    selectedFaultIdArray: [Array],
    selectedTaskitemIdArray: [Array]
  },
  components: {
    UndoneTrouble
  },
  computed: {
    curDeviceRapairInfo () {
      return this.$store.state.equipment.curDeviceRapairInfo
    },
    undoneTroubleAmount () {
      return this.$store.state.equipment.undoneTroubleAmount
    },
    totalCost () { // 总计费用，材料费+人工费
      const cost = (this.materialCost ? this.materialTotalCost : 0) + (this.workCost ? this.workTotalCost : 0)
      return parseFloat(cost.toPrecision(12)) || 0
    }
  },
  data () {
    return {
      submitLoading: false,
      taskitemTypeArray: [],
      newTaskData: {
        repairId: null,
        actionDate: '',
        resourceId: '',
        taskId: null,
        extras: '',
        remarks: '',
        materialServiceId: null,
        workServiceId: null,
        materialCount: 1,
        workCount: 1,
        reportTroubleId: null,
        useReportPic: false
      },
      modifyTaskData: {},
      materialCost: false,
      workCost: false,
      checkTips: '',
      ruleValidate: {
        actionDate: [{ required: true, message: ' ', trigger: 'change' }],
        repairId: [{ required: true, type: 'number', message: ' ', trigger: 'change' }]
      },
      repairDictList: [],
      repairInfo: null,
      selectedTaskitemFiles: [],
      fileList: [],
      materialTotalCost: 0,
      workTotalCost: 0,
      label2: (h) => {
        return h('div', [
          h('span', '未完成任务'),
          h('Badge', {
            style: { 'padding-left': '15px' },
            props: {
              count: this.undoneTroubleAmount
            }
          })
        ])
      }
    }
  },
  destroyed () {
    // 清除维修信息store
    this.$store.commit('set_cur_device_rapair_info', {})
  },
  methods: {
    changeActionDate (date) {
      this.newTaskData.actionDate = date
      if (this.newTaskData.repairId) {
        this.getRepairInfoData(this.newTaskData.actionDate, this.newTaskData.repairId, this.deviceId)
      }
    },
    changeRepairId () {
      this.checkTips = ''
      if (this.newTaskData.repairId) {
        this.handleCheck().then(res => {
          if (res && this.newTaskData.repairId !== this.curDeviceRapairInfo.repairId) {
            this.checkTips = '当前选中设备所选维修项存在未完成任务'
          }
        })
        if (this.newTaskData.actionDate) {
          this.getRepairInfoData(this.newTaskData.actionDate, this.newTaskData.repairId, this.deviceId)
        }

        this.materialCost = false
        this.workCost = false
      }
    },
    /**
     * 获取维修项数据
     */
    getRepairDictData () {
      getRepairDictList().then(res => {
        if (res && !res.errcode) {
          this.repairDictList = res
        }
      })
    },
    /**
     * 获取资源指定维修项的质保情况
     */
    getRepairInfoData (actionDate, repairId, deviceId) {
      getRepairInfo({ actionDate, repairId, deviceId }).then(res => {
        if (res && !res.errcode) {
          this.repairInfo = res
        }
      })
    },
    /**
     * 检验当前选中设备所选维修项是否还存在未完成任务
     */
    handleCheck () {
      return new Promise((resolve, reject) => {
        check({ deviceId: this.deviceId, repairId: this.newTaskData.repairId }).then(res => {
          resolve(res)
        })
      })
    },
    choiceMaterialCost () {
      this.newTaskData.materialServiceId = null
      this.newTaskData.materialCount = 1
      this.materialTotalCost = 0
      if (this.materialCost) {
        this.newTaskData.materialServiceId = this.repairInfo.materialCostList.length ? this.repairInfo.materialCostList[0].id : null
        this.handleChangeMaterial()
      }
    },
    choiceWorkCost () {
      this.newTaskData.workServiceId = null
      this.newTaskData.workCount = 1
      this.workTotalCost = 0
      if (this.workCost) {
        this.newTaskData.workServiceId = this.repairInfo.workCostList.length ? this.repairInfo.workCostList[0].id : null
        this.handleChangeWorkcost()
      }
    },
    /**
     * 切换材料费用
     */
    handleChangeMaterial () {
      if (!this.newTaskData.materialServiceId) {
        this.newTaskData.materialCount = 1
      }
      const materialCost = this.repairInfo.materialCostList.find(x => x.id === this.newTaskData.materialServiceId)
      const cost = (materialCost ? materialCost.price : 0) * this.newTaskData.materialCount
      this.materialTotalCost = parseFloat(cost.toPrecision(12))
    },
    /**
     * 切换人工费用
     */
    handleChangeWorkcost () {
      if (!this.newTaskData.workServiceId) {
        this.newTaskData.workCount = 1
      }
      const workCost = this.repairInfo.workCostList.find(x => x.id === this.newTaskData.workServiceId)
      const cost = (workCost ? workCost.price : 0) * this.newTaskData.workCount
      this.workTotalCost = parseFloat(cost.toPrecision(12))
    },
    handleUpload (file) {
      this.fileList.push(file)
      return false
    },
    handleRemove (file) {
      this.fileList.splice(this.fileList.indexOf(file), 1)
    },
    handleRemoveExitsFiles (file) {
      this.selectedTaskitemFiles.splice(this.selectedTaskitemFiles.indexOf(file), 1)
    },
    handleSubmit () {
      this.$refs.newTaskData.validate(valid => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确认信息无误并提交？',
            onOk: () => {
              this.submitLoading = true
              // 处理计费次数
              this.newTaskData.materialCount = this.materialCost ? this.newTaskData.materialCount : 0
              this.newTaskData.workCount = this.workCost ? this.newTaskData.workCount : 0

              // 处理材料费用id
              let materialServiceId = this.newTaskData.materialServiceId
              if (!this.materialCost || !this.newTaskData.materialServiceId) {
                materialServiceId = ''
              }
              // 处理人工费用id
              let workServiceId = this.newTaskData.workServiceId
              if (!this.workCost || !this.newTaskData.workServiceId) {
                workServiceId = ''
              }

              const uploadData = new FormData()
              uploadData.append('actionDate', this.newTaskData.actionDate)
              uploadData.append('materialServiceId', materialServiceId)
              uploadData.append('repairId', this.newTaskData.repairId)
              uploadData.append('workServiceId', workServiceId)
              uploadData.append('extras', this.newTaskData.extras)
              uploadData.append('materialCount', this.newTaskData.materialCount)
              uploadData.append('remarks', this.newTaskData.remarks || '')
              // uploadData.append('reportTroubleId', this.newTaskData)
              uploadData.append('workCount', this.newTaskData.workCount)
              uploadData.append('useReportPic', this.newTaskData.useReportPic)
              if (this.fileList && this.fileList.length) {
                this.fileList.forEach((file) => {
                  uploadData.append('files', file)
                })
              } else {
                uploadData.append('files', null)
              }
              if (this.dataSource === 2) {
                uploadData.append('reportTroubleId', this.selectedFaultIdArray[0])
              }
              if (this.opreateType === 1) {
                uploadData.append('taskId', this.taskId)
                uploadData.append('deviceId', this.deviceId)
                try {
                  axios.post(
                    process.env.VUE_APP_API_URL_V2 +
                    '/ooh-msp/v3/demand/setrepairtaskitem',
                    uploadData,
                    {
                      timeout: 15000, // 请求超时
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        'ooh-auth': getStorage('ooh-token')
                      },
                      withCredentials: true
                      // onUploadProgress (progress) {
                      //   // 处理上传文件进度条数据
                      //   that.percentage = Math.round(
                      //     (progress.loaded / progress.total) * 100
                      //   )
                      // }
                    }
                  ).then((res) => {
                    this.submitLoading = false
                    if (res.data && res.data.errcode === 0) {
                      // this.selectedResourceIdArray = []
                      // this.selectedTaskitemIdArray = []
                      // if (this.dataSource === 1) {
                      //   // 重新获取库存资源
                      //   this.$store.dispatch('getDeviceData')
                      // } else {
                      //   // 重新获取上报资源
                      //   this.$store.dispatch('getTroubleData')
                      // }
                      this.$emit('handleSetSuccess')

                      this.$Notice.success({ desc: '操作成功' })
                      Object.assign(this.$data, this.$options.data())
                    } else {
                      this.$Notice.error({ desc: res.data.errmsg })
                    }
                  })
                } catch (error) {
                  this.submitLoading = false
                }
              } else {
                uploadData.append('taskitemId', this.selectedTaskitemIdArray[0])
                if (this.selectedTaskitemFiles && this.selectedTaskitemFiles.length) {
                  uploadData.append('taskitemFileIds', JSON.stringify(this.selectedTaskitemFiles.map(x => x.id)))
                }
                try {
                  axios.post(
                    process.env.VUE_APP_API_URL_V2 +
                    '/ooh-msp/v3/demand/updaterepairtaskitem',
                    uploadData,
                    {
                      timeout: 15000, // 请求超时
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        'ooh-auth': getStorage('ooh-token')
                      },
                      withCredentials: true
                      // onUploadProgress (progress) {
                      //   // 处理上传文件进度条数据
                      //   that.percentage = Math.round(
                      //     (progress.loaded / progress.total) * 100
                      //   )
                      // }
                    }
                  ).then((res) => {
                    this.submitLoading = false
                    if (res.data && res.data.errcode === 0) {
                      this.selectedResourceIdArray = []
                      this.selectedTaskitemIdArray = []
                      this.selectedTaskitemFiles = []
                      this.$emit('handleSetSuccess')
                      // this.$store.dispatch('getTaskitemData')
                      this.$Notice.success({ desc: '操作成功' })
                      Object.assign(this.$data, this.$options.data())
                    } else {
                      this.$Notice.error({ desc: res.data.errmsg })
                    }
                  })
                } catch (error) {
                  this.submitLoading = false
                }
              }
            }
          })
        }
      })
    }
  },
  watch: {
    repairInfo: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          if (this.opreateType === 1) {
            this.materialTotalCost = 0
            this.workTotalCost = 0
          }
        }
      }
    },
    curDeviceRapairInfo: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          this.getRepairDictData()
          if (this.opreateType === 2) { // 编辑
            this.getRepairInfoData(val.actionDate, val.repairId, this.deviceId)
          }
          this.modifyTaskData = val
          this.newTaskData.actionDate = this.modifyTaskData.actionDate
          this.newTaskData.repairId = this.modifyTaskData.repairId
          this.materialCost = this.modifyTaskData.applyMateralCount > 0
          this.workCost = this.modifyTaskData.applyWorkCount > 0
          this.newTaskData.remarks = this.modifyTaskData.remark
          this.newTaskData.materialCount = this.modifyTaskData.applyMateralCount || 1
          this.newTaskData.workCount = this.modifyTaskData.applyWorkCount || 1
          this.newTaskData.materialServiceId = this.modifyTaskData.materialServiceId > 0 ? this.modifyTaskData.materialServiceId : null
          this.newTaskData.workServiceId = this.modifyTaskData.workServiceId > 0 ? this.modifyTaskData.workServiceId : null
          this.materialTotalCost = this.modifyTaskData.applyMaterialCost * this.newTaskData.materialCount
          this.workTotalCost = this.modifyTaskData.applyWorkCost * this.newTaskData.workCount
        }
      }
    },
    'newTaskData.materialCount' (val) {
      if (val >= 0) {
        this.handleChangeMaterial()
      }
    },
    'newTaskData.workCount' (val) {
      if (val >= 0) {
        this.handleChangeWorkcost()
      }
    }
  }
}
</script>
